@import url('https: //fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --font-base: 'Manrope', sans-serif;

    --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

    --color-text: #F2F2F2;
    --color-subtext: #909090;
    --color-bg: #1A1A21;
    --color-brand: #5D69CB;
    --color-footer: #0a0a0a;
    --color-tertiary: #333339;
    --color-imfopink: #ef59a1;
    --color-imfoblue: #6ac9c9;
    --color-imfogreen: #94c83d;
    --color-imfopurple: #aa54a0;
}

.gradient__bg {
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 19% 41%, #5D69CB 1%, #1A1A21 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 19% 41%, #5D69CB 1%, #1A1A21 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 19% 41%, #5D69CB 1%, #1A1A21 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 19% 41%, #5D69CB 1%, #1A1A21 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 19% 41%, #5D69CB 1%, #1A1A21 100%);
}

.gradient__text {
    background: var(--gradient-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
    
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }   
}