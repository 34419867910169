.imfonin__hero {
    display: flex;
    /* flex-direction: column; */
}

.imfonin__hero-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin-right: 5rem;
}

.imfonin__hero-content-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.imfonin__hero-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.imfonin__hero-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.imfonin__hero-content_people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.imfonin__hero-content_people img {
    width: 181.79px;
    height: 38px;
}

.imfonin__hero-content_people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.imfonin__hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imfonin__hero-image img {
    width: 100%;
    height: 100%;
}

.imfonin__herocards-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}


@media screen and (max-width: 1050px) {
    .imfonin__hero {
        flex-direction: column;
    }

    .imfonin__hero-content {
        margin: 0 0 3rem;
        flex-direction: column;
    }

    .imfonin__herocards-section {
        display: flex;
        flex-direction: row;
        /* justify-content: center;
            align-items: center;
            margin-top: 2rem; */
    }

}

@media screen and (max-width: 650px) {
    .imfonin__herocards-section {
        display: flex;
        flex-direction: column;
        /* justify-content: center;
                align-items: center;
                margin-top: 2rem; */
    }

    .imfonin__hero-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .imfonin__hero-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .imfonin__hero-content_people {
        flex-direction: column;
    }

    .imfonin__hero-content_people p {
        margin: 0;
    }

    .imfonin__hero-content_input input,
    .imfonin__hero-content_input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .imfonin__hero-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .imfonin__hero-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .imfonin__hero-content_input input,
    .imfonin__hero-content_input button {
        font-size: 12px;
        line-height: 16px;
    }
}