*  {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: 'Vazir', sans-serif;
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}