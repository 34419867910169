.imfonin__navbar {
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 2rem 6rem;
}

.imfonin__navbar-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imfonin__navbar-links_logo {
    margin-right: 2rem;
}

.imfonin__navbar-links_logo img {

    height: 84px;
}

.imfonin__navbar-links_mobilelogo{
    display: none;
}

.imfonin__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.imfonin__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.imfonin__navbar-links_container p,
.imfonin__navbar-sign p,
.imfonin__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1.2rem;
    cursor: pointer;
}

.imfonin__navbar-sign button,
.imfonin__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: var(--color-imfogreen);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px; 
}

.imfonin__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.imfonin__navbar-menu svg {
    cursor: pointer;
}   

.imfonin__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-brand);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.imfonin__navbar-menu_container p {
    margin: 1rem 0;
}   

.imfonin__navbar-menu_container-links-sign {
    display: none;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #999;
    color: #FFF;
    border-radius: 50px;
    text-align: center;

    z-index: 1000;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 16px;
    height: 16px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
        margin-top: 22px;
}

@media screen and (max-width: 1050px) {
    .imfonin__navbar-links_container {
        display: none;
    }
    .imfonin__navbar-menu {
        display: flex;
    }
    
}

@media screen and (max-width: 700px) {
    .imfonin__navbar {
        padding: 2rem 4rem;
    }
}   

@media screen and (max-width: 550px) {
    .imfonin__navbar {
        padding: 2rem;
    }
    .imfonin__navbar-sign {
        display: none;
    }
    .imfonin__navbar-menu_container {
        top: 20px;
    }
    .imfonin__navbar-menu_container-links-sign {
        display: block;
    }
.imfonin__navbar-links_logo{
    display: none;
}
.imfonin__navbar-links_mobilelogo {
    display: flex;
}.imfonin__navbar-links_mobilelogo img{
    width: 128px;
}
}

