.imfonin__case_study {
    display: flex;
    flex-direction: row;
}

.imfonin__case_study-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3rem;
}

.imfonin__case_study-image img {
    width: 100%;
    height: 100%;
}

.imfonin__case_study-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.imfonin__case_study-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71e5ff;
}

.imfonin__case_study-content h4:last-child {
    color: var(--color-subtext);
}

.imfonin__case_study-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

    margin-top: 1rem;
    margin-bottom: 2rem;

}

.imfonin__case_study-content p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text);
    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .imfonin__case_study {
        flex-direction: column;
    }
    .imfonin__case_study-image {
        margin: 0 0 3rem 0;
    }
    
}