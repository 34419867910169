.imfonin__cta {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8rem 14rem;
    /* margin: 4rem; */
    /* border-radius: 1rem; */
/* background: var(--gradient-bar); */
 display: flex;
        flex-direction: column;
    
    
        /* ff 3.6+ */
        background: -moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    
        /* safari 5.1+,chrome 10+ */
        background: -webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    
        /* opera 11.10+ */
        background: -o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    
        /* ie 10+ */
        background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    
        /* global 92%+ browsers support */
        background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.imfonin__cta h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    color: var(--color-imfogreen);
    text-align: center;
    margin-bottom: 1.5rem;
}

.imfonin__cta p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}
/* 
.imfonin__cta input {
    width: 100%;
    margin: 2rem 1rem 1rem;
    display: flex;
} */

.imfonin__cta input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    /* font-weight: 400; */
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-radius: 5px;

}

.imfonin__cta-content_input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: column;
    max-width: 480px;
    
}

.imfonin__cta-content_input input[type="name"], input[type="email"], input[type="phone"], input[type="text"], select {
    flex: 2;
    width: 100%;
    min-height: 48px;
    font-family: var(--font-family);
    /* font-weight: 400; */
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 24px;
}

.imfonin__cta-content_input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-imfogreen);
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
    border-radius: 5px;

}
.imfonin__cta-content_input-select {
    display: flex;
    flex-direction: column;

}


.imfonin__cta-content_input-select_option {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.imfonin__cta-content_input-select_option input[type="checkbox"] {
    margin-right: 8px;
    min-height: 24px;

    vertical-align: top;
}

.imfonin__cta-content_input-select_option label {

    color: #fff;
    font-family: var(--font-family);

    font-size: 20px;
    vertical-align: top;

}

/* Additional styling for hover and focus states */
.imfonin__cta-content_input-select_option input[type="checkbox"]:hover,
.imfonin__cta-content_input-select_option input[type="checkbox"]:focus {
    outline: none;
    border-color: #666;

}

.option-checkbox{
   max-width: 24px;
}



@media screen and (max-width: 1150px) {
    .imfonin__cta {
        padding: 4rem 6rem;
        /* margin: 2rem; */
    }
}

@media screen and (max-width: 960px) {
    .imfonin__cta {
        padding: 4rem 4rem;
        /* margin: 2rem; */
    }
}
@media screen and (max-width: 650px) {
        .imfonin__cta {
            padding: 2rem 3rem;
            /* margin: 2rem; */
        }
.imfonin__cta h1 {
        font-size: 28px;
        line-height: 36px;
    }
    
.imfonin__cta p {
        font-size: 14px;
        line-height: 22px;
    }

    .imfonin__cta-content_input input,
    .imfonin__cta-content_input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {

    .imfonin__cta {
            padding: 2rem;
            margin: 0;

    border-radius: 0;
        }
    
        .imfonin__cta h1 {
            font-size: 24px;
            line-height: 32px;
        }
    
        .imfonin__cta p {
            font-size: 12px;
            line-height: 18px;
        }

    .imfonin__cta-content_input input,
    .imfonin__cta-content_input button {
        font-size: 12px;
        line-height: 16px;
    }
}