.imfonin__herocards-container {
    min-width: 320px;
    display: flex;
    min-height: 380px;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;
    border: 2px solid var(--color-primary);
    border-radius: 16px;
    padding: 1.5rem;

    background: -moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.imfonin__herocards-container__title {
    flex: 1;
    max-width: 380px;
    margin-right: 2rem;
}

.imfonin__herocards-container__title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
}

.imfonin__herocards-container__title div {
    width: 40px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0.5rem 0rem;
}

.imfonin__herocards-container__text {
    flex: 2;
    max-width: 490px;
    display: flex;
}

.imfonin__herocards-container__text ul li {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: var(--color-text);
    list-style-type: none;
    margin-left: 1rem;
}

.imfonin__herocards-container button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-imfogreen);
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
    border-radius: 5px;
    margin-top: 2rem;

}

@media screen and (max-width: 550px) {
    .imfonin__herocards-container__title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .imfonin__herocards-container__text p {
        font-size: 12px;
        line-height: 20px;
    }

    .imfonin__herocards-container {
        margin: 1rem 0;
    }
}