.imfonin__interlude {
    width: 100%;
}

.imfonin__interlude-image {
    width: 100%;
}

.imfonin__interlude-image img {
    width: 100%;
}